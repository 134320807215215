import tramites from 'assets/json/tramites';
import { CustomCard } from 'components/common/CustomCard';
import React, { useEffect } from 'react';
import { Button, Card, Col, Row, Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { checkLastProcedureState, tramiteCreate } from 'redux/actions/tramite';

const InfoAltaMatriculacion = () => {
  const { user } = useSelector(state => state.authReducer);
  const { lastProcedure } = useSelector(state => state.tramiteReducer);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isRegistred = !!user;

  const handleIniciarTramite = () => {
    dispatch(
      tramiteCreate(
        { tipoId: tramites.altaMatriculacion, userId: user.id },
        navigate
      )
    );
  };
  useEffect(() => {
    user &&
      dispatch(checkLastProcedureState(user.id, tramites.altaMatriculacion));
  }, [user]);

  const handleNavigateOwnProcedure = () => {
    navigate('/tramites/' + lastProcedure.id);
  };
  return (
    <CustomCard title="Alta de Matriculación" icon="stamp">
      <Card.Body>
        <Row>
          <Col xs={12}>
            <img src="/banner.jpg" className="img-fluid mb-3" />
          </Col>
        </Row>
        <div className="d-flex justify-content-end mb-1">
          <div>
            {isRegistred &&
              ((lastProcedure && lastProcedure?.estado === 'pendiente') ||
              lastProcedure?.estado === 'finalizado' ? (
                <Button
                  type="button"
                  className="btn btn-primary"
                  style={{
                    cursor: 'pointer'
                  }}
                  onClick={handleNavigateOwnProcedure}
                >
                  Ir a mi trámite
                </Button>
              ) : lastProcedure && lastProcedure.estado === 'rechazado' ? (
                <>
                  <Button
                    type="button"
                    className="btn btn-primary"
                    style={{
                      cursor: 'pointer'
                    }}
                    onClick={handleIniciarTramite}
                  >
                    Iniciar nuevo trámite
                  </Button>
                  <Button
                    type="button"
                    className="btn btn-danger"
                    style={{
                      cursor: 'pointer',
                      marginLeft: '1rem'
                    }}
                    onClick={handleNavigateOwnProcedure}
                  >
                    Ir a mi trámite rechazado
                  </Button>
                </>
              ) : (
                <Button
                  type="button"
                  className="btn btn-primary"
                  style={{
                    cursor: 'pointer'
                  }}
                  onClick={handleIniciarTramite}
                >
                  Iniciar Trámite
                </Button>
              ))}
          </div>
        </div>
        <div>
          <p>
            ¿Cuáles son los requisitos de matriculación? Los requisitos podrá
            visualizarlos en nuestra web ingresando en la solapa Requisitos de
            Matriculación y reuniendo todos los ítems detallados allí entre el
            punto 1 y 9 inclusive.
          </p>
          <Alert variant="info">
            Debe estar registrado para iniciar ese trámite.
          </Alert>
          <p
            style={{
              fontWeight: 700,
              textDecoration: 'underline'
            }}
          >
            ¿Cuáles son los valores de inscripción y matriculación?
          </p>
          <small
            style={{
              color: 'red'
            }}
          >
            A PARTIR DEL 1 DE ABRIL DE 2024
          </small>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Concepto</th>
                <th scope="col">Costo</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Gastos Administrativos (por única vez)</td>
                <td>$ 50.000</td>
              </tr>
              <tr>
                <td>Arancel de Inscripción (por única vez)</td>
                <td>$ 400.000</td>
              </tr>
              <tr>
                <td>Matrícula Anual 2024</td>
                <td>$ 200.000</td>
              </tr>
              <tr>
                <td>Fianza Fiduciaria 2024</td>
                <td>$ 3.000</td>
              </tr>
            </tbody>
          </table>
          <p>
            El Arancel de Inscripción junto con la Matrícula Anual y la Fianza
            Fiduciaria por un total de $603.000.- podrá abonarse en un solo pago
            con descuento del 10% sobre arancel o en planes de pago de 3, 6 o 10
            cuotas sin intereses. Por consultas sobre los planes de pago
            vigentes, comunicarse al 4124-6060
          </p>
          <p
            style={{
              fontWeight: 700,
              textDecoration: 'underline'
            }}
          >
            ¿Puedo matricularme siendo abogado, contador y/o arquitecto?
          </p>
          <p>
            El hecho de tener título de abogado, contador y/o arquitecto no lo
            habilita para matricularse en CUCICBA, deberá hacer la carrera
            universitaria de corredor y una vez obtenido el título y analítico
            correspondiente podrá iniciar el trámite de matriculación. Tengo
            matrícula de corredor otorgada por la Inspección General de
            Justicia, ¿Puedo matricularme? La vieja matrícula de corredor
            otorgada por la Inspección General de Justicia (IGJ) o por el
            Registro Público de Comercio de Capital Federal pudo haberla
            validado ante CUCICBA en los plazos transitorios que establecieron
            para tales fines las leyes 2340 y 3493, habiendo vencido el último
            en diciembre de 2010. Quien no se presentó oportunamente para
            revalidarla, no podrá dar inicio al trámite de matriculación bajo
            esa modalidad ya que actualmente la misma quedó habilitada
            exclusivamente para quienes egresen de la carrera universitaria de
            corredor y presenten la documentación correspondiente.
          </p>
          <p
            style={{
              fontWeight: 700,
              textDecoration: 'underline'
            }}
          >
            ¿Qué universidades dictan la carrera de corredor?
          </p>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Universidad</th>
                <th scope="col">Titulo</th>
              </tr>
            </thead>
            <tbody>
              {titleData.map((title, index) => {
                return (
                  <tr key={index}>
                    <td>{title.university}</td>
                    <td>{title.titleName}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Card.Body>
    </CustomCard>
  );
};

const titleData = [
  {
    university:
      'Pontificia Universidad Católica Argentina "Santa María de los Buenos Aires"',
    titleName:
      'Martillero Público, Corredor (Inmobiliario y Mobiliario), Administrador de Consorcios y Tasador'
  },
  {
    university: 'Universidad Abierta Interamericana',
    titleName: 'Martillero Público, Corredor y Administrador de Consorcio'
  },
  {
    university: 'Universidad Argentina de la Empresa',
    titleName: 'Martillero Público, Corredor y Administrador de Consorcio'
  },
  {
    university: 'Universidad Argentina John F. Kennedy',
    titleName: 'Técnico Universitario en Martillero'
  },
  {
    university: 'Universidad Blas Pascal',
    titleName: 'Martillero y Corredor Público'
  },
  {
    university: 'Universidad Católica de Cuyo',
    titleName: 'Corredor de Comercio y Martillero'
  },
  {
    university: 'Universidad Católica de La Plata',
    titleName: 'Martillero y Corredor de Comercio'
  },
  {
    university: 'Universidad Católica de Salta',
    titleName: 'Corredor Inmobiliario y Martillero'
  },
  {
    university: 'Universidad Católica de Santa Fe',
    titleName: 'Martillero Público y Corredor Inmoviliario'
  },
  {
    university: 'Universidad Católica de Santiago del Estero',
    titleName:
      'Técnico Universitario en Martillero Público, Corredor y Administrador de Consorcios'
  },
  {
    university: 'Universidad Champagnat',
    titleName:
      'Corredor Público Inmobiliario, Corredor de Comercio y Martillero Público'
  },
  {
    university: 'Universidad de la Cuenca del Plata',
    titleName:
      'Técnico Universitario en Tasación, Martillero Público y Corredor'
  },
  {
    university:
      'Universidad de la Fraternidad de Agrupaciones Santo Tomás de Aquino',
    titleName: 'Martillero y Corredor Público'
  },
  {
    university: 'Universidad de Mendoza',
    titleName: 'Corredor Inmobiliario'
  },
  {
    university: 'Universidad de Morón',
    titleName: 'Tasador, Martillero Público y Corredor'
  },
  {
    university: 'Universidad de San Pablo - Tucumán',
    titleName: 'Corredor Inmobiliario'
  },
  {
    university: 'Universidad del Centro Educativo Latinoamericano',
    titleName: 'Martillero Público y Corredor Inmobiliario'
  },
  {
    university: 'Universidad del Museo Social Argentino',
    titleName: 'Martillero Público y Corredor de Comercio'
  },
  {
    university: 'Universidad del Salvador',
    titleName: 'Martillero Público y Corredor de Comercio'
  },
  {
    university: 'Universidad Empresarial Siglo 21',
    titleName: 'Martillero y Corredor Universitario'
  },
  {
    university: 'Universidad Maimónides',
    titleName: 'Martillero Público y Corredor de Comercio'
  },
  {
    university: 'Universidad Nacional de Cordoba',
    titleName: 'Martillero y Corredor Público'
  },
  {
    university: 'Universidad Nacional de Formosa',
    titleName: 'Tasador, Martillero Público y Corredor'
  },
  {
    university: 'Universidad Nacional de General San Martin',
    titleName: 'Martillero Público y Corredor de Comercio'
  },
  {
    university: 'Universidad Nacional de La Matanza',
    titleName: 'Martillero, Corredor Público y Tasador'
  },
  {
    university: 'Universidad Nacional de La Plata',
    titleName: 'Técnico Superior Universitario de Martillero Público y Corredor'
  },
  {
    university: 'Universidad Nacional de La Rioja',
    titleName:
      'Técnico Universitario en Tasación, Martillero Público y Corredor'
  },
  {
    university: 'Universidad Nacional de Lomas de Zamora',
    titleName: 'Martillero Público y Corredor de Comercio'
  },
  {
    university: 'Universidad Nacional de Mar del Plata',
    titleName: 'Martillero, Corredor Público y Tasador'
  },
  {
    university: 'Universidad Nacional de Tres de Febrero',
    titleName: 'Técnico en Martillero Público y Corredor Inmobiliario'
  },
  {
    university: 'Universidad Nacional del Litoral',
    titleName: 'Martillero Público y Corredor de Comercio'
  },
  {
    university: 'Universidad Nacional del Nordeste',
    titleName: 'Martillero Público y Corredor de Comercio'
  },
  {
    university:
      'Universidad Nacional del Noroeste de la Provincia de Buenos Aires',
    titleName: 'Martillero y Corredor Público'
  },
  {
    university: 'Universidad Nacional del Sur',
    titleName: 'Martillero y Corredor Público'
  },
  {
    university: 'Universidad Provincial del Sudoeste',
    titleName: 'Martillero y Corredor Público'
  },
  {
    university: 'Universidad Tecnológica Nacional',
    titleName: 'Técnico Superior en Negociación de Bienes'
  },
  {
    university: 'Universidad Nacional de la Patagonia San Juan Bosco',
    titleName: 'Técnico Universitario en Martillero Público y Corredor'
  },
  {
    university: 'Universidad de la Marina Mercante',
    titleName:
      'Martillero/a Público/a, Corredor/a y Administrador/a de Consorcios'
  }
];

export default InfoAltaMatriculacion;
