import axios from 'axios';
import { CustomCard } from 'components/common/CustomCard';
import RenderPreview from 'components/common/RenderPreview';
import React, { useEffect, useState } from 'react';
import { Card, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const Oblea = () => {
  const { user } = useSelector(state => state.authReducer);

  const [oblea, setOblea] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    if (user?.datos?.oblea) {
      try {
        const { data } = await axios.get(
          process.env.REACT_APP_SERVER +
            '/pdf/oblea/' +
            user.id +
            '/domicilioCasaCentral'
        );
        setOblea(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [user]);

  const handleChange = async e => {
    if (user?.datos?.oblea) {
      setLoading(true);
      try {
        const { data } = await axios.get(
          process.env.REACT_APP_SERVER +
            '/pdf/oblea/' +
            user.id +
            '/' +
            e.target.value
        );
        setOblea(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  return (
    <CustomCard title="Oblea" icon="print">
      <select
        name=""
        id="sucursales"
        className="form-select"
        onChange={handleChange}
      >
        <option value="domicilioCasaCentral">Casa Central</option>
        {user?.datos?.domicilioSucursal1 && (
          <option value="domicilioSucursal1">Sucursal 1</option>
        )}

        {user?.datos?.domicilioSucursal2 && (
          <option value="domicilioSucursal2">Sucursal 2</option>
        )}
      </select>
      <Card.Body>
        {loading ? (
          <Spinner animation="border" variant="primary" />
        ) : oblea ? (
          <div style={{ maxWidth: '300px' }}>
            <RenderPreview isSmall alt="oblea" preview={oblea} />
          </div>
        ) : (
          <div>
            <p>No tenés oblea</p>
            <p>
              Para obtener tu oblea, tenés que realizar el trámite de Alta de
              Matriculación y la declaración jurada de Actividad Comercial
            </p>
          </div>
        )}
      </Card.Body>
    </CustomCard>
  );
};

export default Oblea;
